import Bulma from '@vizuaalog/bulmajs';
//import BulmaValidator from "bulma-validator";
import $ from 'jquery';

function _req_text(v) {
  return v.length > 0 && v.length <= 100;
}

function _req_email(elem) {
  return elem.match(
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
  );
}

function initform(formname, elements) {
  for (const n of elements) {
    var e = $(formname + ' [name=' + n + ']');
    if (localStorage.getItem(formname + '.' + n)) {
      if (e.prop('type') === 'checkbox') {
        if (localStorage.getItem(formname + '.' + n) === 'true') {
          e.attr('checked', 'true');
        } else {
          e.removeAttr('checked');
        }
      } else {
        $(formname + ' [name=' + n + ']').val(
          localStorage.getItem(formname + '.' + n),
        );
      }
    }
    $(formname + ' [name=' + n + ']').on('change', function () {
      validate(formname, elements);
      if ($(this).prop('type') === 'checkbox') {
        localStorage.setItem(formname + '.' + n, $(this).prop('checked'));
      } else {
        localStorage.setItem(formname + '.' + n, this.value);
      }
    });
  }
}

function clearform(formname, elements) {
  for (const n of elements) {
    localStorage.removeItem(formname + '.' + n);
    $(formname + ' [name=' + n + ']').val('');
  }

  $('[name=place]').val($('[name=place]').attr('defaultval'));
  $('[name=occupation]').selectedIndex = 0;
  $('[name=subscribe]').prop('checked', true);
}

function validate(formname, elements) {
  var _r = true;
  for (const n of elements) {
    var r = true;
    var v = $(formname + ' [name=' + n + ']').val();
    var e = $(formname + ' [name=' + n + ']');
    var t = e.attr('data-validation');
    if (t === 'texty') {
      r = r && _req_text(v);
    } else if (t === 'email') {
      r = r && _req_email(v);
    }
    if (!r) {
      e.removeClass('is-success');
      e.addClass('is-danger');
      e.parent().parent().children('.help').removeClass('is-hidden');
    } else {
      e.removeClass('is-danger');
      e.addClass('is-success');
      e.parent().parent().children('.help').addClass('is-hidden');
    }
    _r = _r && r;
  }
  return _r;
}

document.addEventListener('DOMContentLoaded', function () {
  var formname = '#contact';
  var elements = [
    'name',
    'email',
    'organization',
    'place',
    'occupation',
    'functionality',
    'subscribe',
  ];

  initform(formname, elements);

  $('#pd-consent').change(function () {
    console.log($(this).val());
    $('#sendform').prop('disabled', !this.checked);
  });

  $('#sendform').click(function () {
    if (!validate(formname, elements)) {
      return;
    }

    var data = {};
    for (const n of elements) {
      const e = $(formname + ' [name=' + n + ']');
      if ($(e).prop('type') === 'checkbox') {
        data[n] = $(e).prop('checked');
      } else {
        data[n] = $(e).val();
      }
    }

    grecaptcha
      .execute('6LfMmsAiAAAAAJW9x_wcFU7ffxdegx7sgUlnPnwq', { action: 'submit' })
      .then(function (token) {
        data['gcaptcha'] = token;
        $.ajax({
          data: JSON.stringify(data),
          method: 'post',
          url: '/forms/demo',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          complete: function (_data, textStatus) {
            if (('' + _data.status).startsWith('2')) {
              $('#errorform').addClass('is-hidden');
              clearform(formname, elements);
              $('#email-submitted').html(data['email']);
              $('#successform').removeClass('is-hidden');
              $(formname).addClass('is-hidden');
            } else {
              $('#errorform').html(
                'Internal Error. Probably you need to fill the captcha again' +
                  '<br>' +
                  _data.status +
                  ' ' +
                  _data.responseText,
              );
              $('#errorform').removeClass('is-hidden');
            }
          },
        });
      });
  });
  // .catch(function () {
  //   console.log('captcha error');
  // });

  $('#clearform').click(function () {
    clearform(formname, elements);
  });

  $(formname).submit(function (e) {
    e.preventDefault();
  });
});
